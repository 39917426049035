<template>
  <span>
    <a-dropdown class="taxes-drop" placement="bottomRight">
      <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
        <img
          src="@/assets/images/dashboard/contracts/info.png"
          alt="img"
          width="16"
        />
      </a>
      <a-menu slot="overlay">
        <a-menu-item v-if="contractPricingData.hotel.totalProfit > 0">
          <a-row type="flex" justify="space-between" style="font-size: 11px">
            <a-col style="width: 250px">
              Lucratividade
              <font class="cprimary" style="font-size: 10px"
                ><i> Hotel </i></font
              ></a-col
            >
            <a-col
              ><b class="cgreen">
                {{ formatPricePtBr(contractPricingData.hotel.totalProfit) }}
              </b>
            </a-col>
          </a-row>
        </a-menu-item>

        <a-menu-item v-if="contractPricingData.hotel.totalIncentives > 0">
          <a-row type="flex" justify="space-between" style="font-size: 12px">
            <a-col style="width: 200px">
              Incentivos
              <font class="cprimary" style="font-size: 10px"
                ><i> Hotel </i></font
              ></a-col
            >
            <a-col
              ><b class="cgreen">
                {{ formatPricePtBr(contractPricingData.hotel.totalIncentives) }}
              </b>
            </a-col>
          </a-row>
        </a-menu-item>

        <a-menu-item v-if="contractPricingData.flight.totalProfit > 0">
          <a-row type="flex" justify="space-between" style="font-size: 12px">
            <a-col style="width: 200px">
              Lucratividade
              <font class="cprimary" style="font-size: 10px"
                ><i> Aéreo </i></font
              ></a-col
            >
            <a-col
              ><b class="cgreen">
                {{ formatPricePtBr(contractPricingData.flight.totalProfit) }}
              </b>
            </a-col>
          </a-row>
        </a-menu-item>

        <a-menu-item v-if="contractPricingData.flight.totalIncentives > 0">
          <a-row type="flex" justify="space-between" style="font-size: 12px">
            <a-col style="width: 200px">
              Incentivos
              <font class="cprimary" style="font-size: 10px"
                ><i> Aéreo </i></font
              ></a-col
            >
            <a-col
              ><b class="cgreen">
                {{
                  formatPricePtBr(contractPricingData.flight.totalIncentives)
                }}
              </b>
            </a-col>
          </a-row>
        </a-menu-item>

        <a-menu-item v-if="contractPricingData.service.totalProfit > 0">
          <a-row type="flex" justify="space-between" style="font-size: 12px">
            <a-col style="width: 200px">
              Lucratividade
              <font class="cprimary" style="font-size: 10px"
                ><i> Serviço </i></font
              ></a-col
            >
            <a-col
              ><b class="cgreen">
                {{ formatPricePtBr(contractPricingData.service.totalProfit) }}
              </b>
            </a-col>
          </a-row>
        </a-menu-item>

        <a-menu-item v-if="contractPricingData.service.totalIncentives > 0">
          <a-row type="flex" justify="space-between" style="font-size: 12px">
            <a-col style="width: 200px">
              Incentivos
              <font class="cprimary" style="font-size: 10px"
                ><i> Serviço </i></font
              ></a-col
            >
            <a-col
              ><b class="cgreen">
                {{
                  formatPricePtBr(contractPricingData.service.totalIncentives)
                }}
              </b>
            </a-col>
          </a-row>
        </a-menu-item>

        <a-menu-item v-if="contractPricingData.package.totalProfit > 0">
          <a-row type="flex" justify="space-between" style="font-size: 12px">
            <a-col style="width: 200px">
              Lucratividade
              <font class="cprimary" style="font-size: 10px"
                ><i> Pacote Completo </i></font
              ></a-col
            >
            <a-col
              ><b class="cgreen">
                {{ formatPricePtBr(contractPricingData.package.totalProfit) }}
              </b>
            </a-col>
          </a-row>
        </a-menu-item>

        <a-menu-item v-if="contractPricingData.package.totalIncentives > 0">
          <a-row type="flex" justify="space-between" style="font-size: 12px">
            <a-col style="width: 200px">
              Incentivos
              <font class="cprimary" style="font-size: 10px"
                ><i> Pacote Completo </i></font
              ></a-col
            >
            <a-col
              ><b class="cgreen">
                {{
                  formatPricePtBr(contractPricingData.package.totalIncentives)
                }}
              </b>
            </a-col>
          </a-row>
        </a-menu-item>

        <a-menu-item
          v-if="contractPricingData.financialCosts.paymentCard.total > 0"
        >
          <a-row type="flex" justify="space-between" style="font-size: 12px">
            <a-col style="width: 200px">
              Lucratividade
              <font class="cprimary" style="font-size: 10px"
                ><i>Carta de Crédito</i></font
              ></a-col
            >
            <a-col
              ><b class="red">
                -
                {{
                  formatPricePtBr(
                    contractPricingData.financialCosts.paymentCard.total
                  )
                }}</b
              >
            </a-col>
          </a-row>
        </a-menu-item>

        <a-menu-item
          v-if="contractPricingData.financialCosts.marketing.livelo.total > 0"
        >
          <a-row type="flex" justify="space-between" style="font-size: 12px">
            <a-col style="width: 200px">
              Livelo
              <font class="cprimary" style="font-size: 10px"
                ><i>Marketing</i></font
              ></a-col
            >
            <a-col
              ><b class="red">
                -
                {{
                  formatPricePtBr(
                    contractPricingData.financialCosts.marketing.livelo.total
                  )
                }}</b
              >
            </a-col>
          </a-row>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </span>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "ContractProfitList",
  props: {
    tempContract: Object,
    contractPricingData: Object,
  },
  mixins: [formatThings],
};
</script>

<style lang="sass" scoped>
.ant-dropdown-menu-item
  border-bottom: 1px solid #eee
</style>
